<template>
  <div class="container" v-bind:key="page" v-if="currentPage">  
    <div v-if="currentPage.data.status_code == 404">
      <app-section :title="$t('Página no encontrada')"></app-section>
    </div>
    <div v-else>
      <app-section :title="currentPage.data.title"></app-section>
      <div v-html="currentPage.data.content"></div>    
    </div>
  </div>
</template>

<script>
import AppSection from "@/components/AppSection";
import {mapGetters} from "vuex";

export default {
  name: "pages",
  components:{
    AppSection
  },
  props:{
    page: {
      default(){
        return null
      },
      type: String
    }
  },
  computed:{
    ...mapGetters(["translated_pages", "locale"]),
    currentPage(){
      return this.translated_pages[this.locale].find(p => p.slug === this.page);
    }
  },
  created(){
    // this.$store.dispatch("fetchPages");
    this.$store.dispatch("fetchPage",this.page);
  }
}
</script>

<style scoped>

</style>